// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_errorMessage__EXj\\+Q {
  padding: var(--space-base);
}

.login_qr-background__wuprY {
  background: white;
  margin: 0 calc((25rem - 128px) / 2);
}

.login_passwords-rule__yZ6Jy {
  display: inline-block;
  margin-left: var(--space-base);
}
.login_passwords-rule-icon__VLQCg {
  padding-right: var(--space-base);
}`, "",{"version":3,"sources":["webpack://./src/apps/loginPage/login.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACE,iBAAA;EACA,mCAAA;AACF;;AAEA;EACE,qBAAA;EACA,8BAAA;AACF;AAAE;EACI,gCAAA;AAEN","sourcesContent":[".errorMessage {\n  padding: var(--space-base);\n}\n\n.qr-background {\n  background: white;\n  margin: 0 calc((25rem - 128px) / 2);\n}\n\n.passwords-rule {\n  display: inline-block;\n  margin-left: var(--space-base);\n  &-icon {\n      padding-right: var(--space-base);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": `login_errorMessage__EXj+Q`,
	"qr-background": `login_qr-background__wuprY`,
	"passwords-rule": `login_passwords-rule__yZ6Jy`,
	"passwords-rule-icon": `login_passwords-rule-icon__VLQCg`
};
export default ___CSS_LOADER_EXPORT___;
