import React from 'react';
import Configurations from '../../components/configurationsTable';
import styles from './configurations.module.scss';

function ConfigTable() {
  return (<div className={`${styles['wrapper']} flex flex-row`}>
    <div className={`${styles['config-table']}`} data-cy={`config-table`}>
      <div className={styles['config-table-actions']}>
      </div>
      <div className={`${styles['config-table-container']} layer1`}>
        <Configurations></Configurations>
      </div>
    </div>
  </div>);
}

export default ConfigTable;
