import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import QRCode from 'react-qr-code';
import { userService } from '../../services';
import styles from './login.module.scss';
import logo from '../../assets/logo.png';

export default function Login() {
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [totpCode, setOTP] = useState('');
  const [passwordValid] = useState({
    passwordsExist: false,
    passwordsMatch: false,
    correctLength: false,
    containsNumber: false,
    lowercaseLetter: false,
    uppercaseLetter: false,
  });
  const [status, setStatus] = useState('ready');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [qrUrl, setQRUrl] = useState();
  const navigate = useNavigate();
  const rules = [
    { condition: 'passwordsMatch', rule: 'Passwords must match' },
    { condition: 'correctLength', rule: 'Password must be between 8 and 32 characters' },
    { condition: 'containsNumber', rule: 'Password must contain a number' },
    { condition: 'lowercaseLetter', rule: 'Password must contain a lowercase letter' },
    { condition: 'uppercaseLetter', rule: 'Password must contain an uppercase letter' },
  ];

  function handleKeyPress(key) {
    if (key === 'Enter') {
      status === 'mfa-invalid'
        ? verifyUser()
        : loginUser();
    }
  }

  function setPasswordValid(originalPassword, repeatedPassword) {
    passwordValid.passwordsExist = originalPassword && repeatedPassword;
    passwordValid.passwordsMatch = originalPassword === repeatedPassword;
    passwordValid.correctLength = originalPassword.length >= 8 && originalPassword.length <= 32;
    passwordValid.containsNumber = new RegExp('[0-9]').test(originalPassword);
    passwordValid.lowercaseLetter = new RegExp('[a-z]').test(originalPassword);
    passwordValid.uppercaseLetter = new RegExp('[A-Z]').test(originalPassword);
  }

  function validatePassword() {
    return !Object.keys(passwordValid).reduce((rulesPassed, passwordRule) => (passwordValid[passwordRule] && rulesPassed), true);
  }

  async function loginUser() {
    try {
      setLoading(true);
      setError('');
      await userService.login(emailId, password, totpCode, status === 'temp-password' ? newPassword : undefined);
      setLoading(false);
      navigate('/dashboard');
    }
    catch (e) {
      setLoading(false);
      if (e.message.includes('verification required')) {
        setStatus('mfa-invalid');
        setQRUrl(e.message.split(' - ')[2]);
      } else if (e.message.includes('New password required')) {
        setStatus('temp-password');
      } else if (e.message.includes('OTP-required')) {
        setStatus('OTP-required');
      } else {
        setError('p-invalid');
      }
    }
  }

  async function verifyUser() {
    try {
      setError('');
      setLoading(true);
      await userService.verifyUser(emailId, totpCode);
      setOTP(null);
      setEmailId(null);
      setPassword(null);
      setLoading(false);
      setStatus('ready');
    }
    catch (e) {
      setLoading(false);
      setError('p-invalid');
    }
  }

  function disableLogin() {
    return status === 'temp-password'
      ? validatePassword()
      : !(emailId && password) || loading;
  }

  function postLoginMessage() {
    switch (status) {
      case 'temp-password':
        return resetPasswords();
      case 'mfa-invalid':
        return QrCode();
      case 'OTP-required':
        return <div className='w-25rem p-1 unauthed-section'>
          <InputText className={`w-full bg-none color ${error}`} type='text' placeholder='Timed OTP' value={totpCode}
            onChange={(e) => setOTP(e.target.value)} onKeyDown={(e) => handleKeyPress(e.key)} />
          {error === 'p-invalid'
            ? <LoginQrErrorMessage />
            : <p>
            Please provide a one time passcode from your registered authenticator.
            </p>}
          <LoginButtons></LoginButtons>
        </div>;
      case 'ready': return <div className='w-25rem unauthed-section'>
        <div className='mt-4 p-1'>
          <InputText className={`w-full bg-none color ${error}`} type='text' placeholder='Email' value={emailId}
            onChange={(e) => setEmailId(e.target.value)} onKeyDown={(e) => handleKeyPress(e.key)} />
        </div>
        <div className='p-1 color'>
          <Password className={`w-full color ${error}`} value={password} placeholder='Password'
            onChange={(e) => setPassword(e.target.value)} toggleMask feedback={false} onKeyDown={(e) => handleKeyPress(e.key)} />
        </div>
        {error === 'p-invalid' ? <LoginErrorMessage /> : null}
        <LoginButtons></LoginButtons>
      </div>;
      default: return null;
    }
  }

  const LoginButtons = () => (
    <div className='button-links'>
      <div className='mt-3'>
        <Button className='w-full bg-white color' disabled={disableLogin()}
          label={ status === 'temp-password' ? 'Set password' : 'Login' }
          onClick={loginUser} />
      </div>
      <div className='link-div'>
        <a href='/forgotten-password' className='link' onClick={() => navigate('/forgotten-password')}>Forgotten password</a>
      </div>
      <div className='link-div'>
        <a href='/set-password' className='link' onClick={() => navigate('/set-password')}>Reset password with token</a>
      </div>
    </div>
  );

  const LoginErrorMessage = () => (
    <div className={styles.errorMessage} data-cy="login-error">
      You have entered an incorrect email, password or code.<br />
      Please check your credentials and try again. If the problem persists,
      please contact your system administrator
    </div>
  );

  function resetPasswords() { return (
    <div className='w-25rem color unauthed-section'>
      <div className='w-25rem p-1 color unauthed-section'>
        <Password className={`w-full color ${error}`} value={newPassword} placeholder='New Password'
          onChange={(e) => { setNewPassword(e.target.value); setPasswordValid(newPassword, e.target.value); }}
          onKeyDown={(e) => {if (validatePassword()) handleKeyPress(e.key);}}
          toggleMask feedback={false} />
      </div>
      <div className='p-1'>
        <Password className={`w-full color ${status}`} value={repeatPassword} placeholder='Repeat new password'
          onChange={(e) => { setRepeatPassword(e.target.value); setPasswordValid(newPassword, e.target.value); }}
          onKeyDown={(e) => {if (validatePassword()) handleKeyPress(e.key);}}
          toggleMask feedback={false} />
      </div>
      <div className='passwords'> {rules.map(rule => generateRule(rule))} </div>
      <p>Please enter a new password to overwrite your current temporary password.</p>
      <LoginButtons></LoginButtons>
    </div>
  );}

  const LoginQrErrorMessage = () => (
    <div className={styles.errorMessage} data-cy="login-error">
      You have entered an incorrect token.<br />
      Please check your credentials and try again. If the problem persists,
      please contact your system administrator
    </div>
  );

  function QrCode() { return <div className='w-25rem unauthed-section'>
    <div className={styles['qr-background']}>
      <QRCode
        size={128}
        value={qrUrl}
        viewBox={`0 0 128 128`}
      />
    </div>
    <p>
      Please scan the qr code into your authenticator. Once successful please supply your one time password to verify and login.
    </p>
    <InputText className={`w-full bg-none color ${error}`} type='text' placeholder='Timed OTP' value={totpCode}
      onChange={(e) => setOTP(e.target.value)} onKeyDown={(e) => handleKeyPress(e.key)} />
    <div className='p-1 mt-3'>
      <Button className='w-full bg-white color' label='Verify'
        onClick={verifyUser} />
    </div>
  </div>;}

  function generateRule({ condition, rule }) {
    return <span className={styles['passwords-rule']}>
      <i className={`pi ${styles['passwords-rule-icon']} ${passwordValid[condition] ? 'pi-check' : 'pi-times'}`}></i>
      <span className={styles['passwords-rule-text']}>{rule}</span>
    </span>;
  }

  return (
    <div className='heading'>
      <div className='logoContainer'>
        <img src={logo} alt='midlands steel logo' />
      </div>
      {postLoginMessage()}
      <div className='w-30rem text-justify'>
        <p>
          Log in is only allowed for authorized users. If you are not an authorized user, please exit immediately.
          In accordance with requirements of data protection laws,
          we hereby inform you that personally identifiable information will be handled in log files for legal,
          security and cost reasons.
          <br></br><br></br>
          © Nova, 2023-2024. All rights reserved.
        </p>
      </div>
    </div>
  );
}
