import React, { useEffect, useCallback, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { default as Filters } from '../../components/filters';
import ServiceErrorMessage from '../../components/serviceErrorMessage';
import OrderFilterCard from '../../components/orderFilterCard';
import { utils } from '../../utils';
import { processService } from '../../services';
import styles from './process.module.scss';

function Processes() {
  const [processes, setProcesses] = useState([]);
  const dt = useRef(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [activeCard, setActiveCard] = useState();
  const [cardFilters, setCardFilters] = useState();
  const columns = [
    { field: 'type', header: 'Status', sortable: true, filter: true,
      filterElement: Filters.dropdownFilterTemplate, showFilterMatchModes: false },
    { field: 'working_bucket', header: 'Bucket', sortable: true, filter: true },
    { field: 'CreationDate', header: 'Date', dataType: 'date', sortable: true, filter: true },
    { field: 'FileNameWithoutDate', header: 'File', sortable: true, filter: true },
    { field: 'EncryptionExtension', header: 'Execution time', dataType: 'numeric', sortable: true, filter: true, body: numericBody },
    { field: 'OriginalKey', header: 'Source bank', sortable: true, filter: true, body: bankBody },
    { field: 'actions', dataType: 'numeric', body: actionsBody },
    { field: 'today', dataType: 'boolean', hidden: true, filter: true,
      filterElement: Filters.dropdownFilterTemplate, showFilterMatchModes: false,
    },
    { field: 'thisWeek', dataType: 'boolean', hidden: true, filter: true,
      filterElement: Filters.dropdownFilterTemplate, showFilterMatchModes: false },
  ];
  const [filters, setFilters] = useState([]);
  const [stats, setStats] = useState({
    Today: 0,
    'Last 7 Days': 0,
    Passed: 0,
    Failed: 0,
    Unprocessed: 0,
    Expected: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const result = await processService.getProcesses();
      setStats(result.stats);
      setProcesses(result.processes.map(process => mapProcess(process)));
      setFilters({
        working_bucket: { matchMode: FilterMatchMode.CONTAINS },
        type: { matchMode: FilterMatchMode.IN, options: ['Success', 'Failed', 'Unprocessed', 'Pending'] },
        FileNameWithoutDate: { matchMode: FilterMatchMode.CONTAINS },
        OriginalKey: { matchMode: FilterMatchMode.CONTAINS },
        EncryptionExtension: { matchMode: FilterMatchMode.CONTAINS },
        CreationDate: { matchMode: FilterMatchMode.DATE_IS },
        today: { matchMode: FilterMatchMode.EQUALS },
        thisWeek: { matchMode: FilterMatchMode.EQUALS },
      });
      const activeCardState = utils.getUrlParam(window.location.search, 'filter') || 'Total';
      const cardFilterState = {
        Today: [{ today: true }],
        Last7Days: [{ thisWeek: true }],
        Passed: [{ type: ['Success'] }],
        Failed: [{ type: ['Failed'] }],
        Unprocessed: [{ type: ['Unprocessed'] }],
        Expected: [{ type: ['Pending'] }],
      };
      setCardFilters(cardFilterState);
      setActiveCard(activeCardState);
    } catch (e) {
      //do nothing
    }
    finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => { fetchData(); }, [fetchData]);

  function mapProcess(process) {
    if (process.type !== 'Pending') return process;
    process.working_bucket = '----';
    process.CreationDate = '----';
    process.FileNameWithoutDate = process.file_name;
    return process;
  }

  function numericBody(process) {
    return isNaN(process.EncryptionExtension) ? 0 : process.EncryptionExtension;
  }
  function bankBody(process) {
    return process.OriginalKey ? process.OriginalKey.split('/')[0] : process.sender_client;
  }
  function actionsBody() {
    return null;
  }

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const rowExpansionTemplate = (process) => (
    <div>
      <div>{process.typeReason}</div>
      <div>{process.SystemError}</div>
    </div>
  );

  function setFilterCard(e, cardName) {
    setActiveCard(cardName);
    customiseFilters(cardName === 'Total' ? null : cardFilters[cardName]);
    e.stopPropagation();
  }

  function customiseFilters(filter) {
    debugger //eslint-disable-line
    Object.keys(filters).forEach(key => filters[key].value = filter && filter[0][key] ? filter[0][key] : null);
    //TODO revisit if preset filter get more complicated
    setFilters(JSON.parse(JSON.stringify(filters)));
  }

  const OrderCard = (stat) =>
    <OrderFilterCard props={{
      name: stat.name,
      shortName: stats[stat.name].shortName || stat.name,
      value: stats[stat.name],
      colSize: stat.size,
      isActive: activeCard === stat.name,
      onClick: function(e) {setFilterCard(e, utils.capitalize(stat.name));},
      onActiveClick: function(e) {setFilterCard(e, 'Total');},
    }}></OrderFilterCard>;

  function generateColumn(column) {
    column.header = column.header || utils.capitalize(column.field);
    column.filterField = column.filterField || column.field;

    return <Column {...column} key={column.field}
    ></Column>;
  }

  const rowExpansionIcon = (rowData) => {
    return expandedRows.some(obj => JSON.stringify(obj) === JSON.stringify(rowData.state.editingRowData)) ? 'pi pi-eye' : 'pi pi-eye-slash';
  };


  return (<div className={styles['wrapper']}>
    <div className={`layer1 ${styles['processes']}`} data-cy={`processes`}>
      <div className={`flex flex-row ${styles['processes-headers']}`}>
        <div className={`block lg:hidden col-12 ${styles['processes-headers-wrapper']}`}>
          {Object.keys(stats).map((stat) => <OrderCard key={`${stat}-key`} name={stat} size={4}></OrderCard>)}
        </div>
        <div className={`hidden md:block col-12 ${styles['order-wrapper']}`}>
          {Object.keys(stats).map((stat) => <OrderCard key={`${stat}-key`} name={stat} size={2}></OrderCard>)}
        </div>
      </div>
      <div className={styles['processes-table']}>
        <div className={styles['processes-table-actions']}>
          <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={(e) => setFilterCard(e, 'Total')} />
          <span className={`${styles['processes-table-actions-right']}`}>
            <i className={`pi pi-file ${styles['process-table-actions-right-export']}`} onClick={() => exportCSV(false)}></i>
          </span>
        </div>
        <DataTable ref={dt} value={processes} paginator rows={10} rowsPerPageOptions={[10, 20, 40]} sortField="date"
          loading={loading} filters={filters} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate} emptyMessage={error ? <ServiceErrorMessage /> : 'No data'}>
          <Column expander rowToggler={rowExpansionIcon}></Column>
          {columns.map(column => generateColumn(column))}
        </DataTable>
      </div>
    </div>
  </div>);
}

export default Processes;
