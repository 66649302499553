import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { userService, menuService } from '../services';
import './menu.scss'; //not using modules as it modifies p-components too

function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const menu = menuService.getMenu(userService.getLoggedInUser() || { roles: [] });
  const [activeLocation, setLocation] = useState();
  const [activeParent, setParent] = useState();

  useEffect(() => {
    menu.forEach(menuItem => {
      if (menuItem.children) {
        const child = menuItem.children.find(child => child.nonExactMatch
          ? location.pathname.includes(child.route)
          : child.route === location.pathname);
        if (child) {
          setLocation(child.name);
          setParent(menuItem.name);
        }
      } else if (menuItem.route === location.pathname) {
        setLocation(menuItem.name);
        setParent(null);
      }
    });
  }, [location, menu]);

  function generateMenu() {
    function generateMenuItem(menuItem) {
      return (menuItem.exclude
        ? null
        : <a key={menuItem.name} href={menuItem.route}
          className={`${activeLocation === menuItem.name ? 'menu-item-active' : null} menu-item `}
          onClick={()=>activeLocation === menuItem.name ? false : navigate(menuItem.route)}>{menuItem.name}</a>);
    }
    return menu.map(menuItem => {
      if (menuItem.children) {
        return (
          <Accordion key={`${menuItem.name}-menu-accordion`} className='menu-accordion'>
            <AccordionTab header={menuItem.name}
              className={`menu-accordion-header ${activeParent === menuItem.name ? 'menu-header-active' : null}`}>
              {menuItem.children.map(child => generateMenuItem(child))}
            </AccordionTab>
          </Accordion>
        );}
      else {
        return generateMenuItem(menuItem);
      }
    });
  }

  return (
    <div className='app-menu-container'>
      <Sidebar modal={false} className={'light'} visible={visible} onHide={() => setVisible(false)}>
        <div className='menu'>
          <div className='menu-header' onClick={() => setVisible(false)}>
            <i className='pi pi-align-justify'></i>
            <span className='menu-header-title'>Menu</span>
            <i className='menu-header-close pi pi-times'></i>
          </div>
          {generateMenu()}
        </div>
        <Button className='bg-none color logout-button' label='Log out' onClick={() => userService.logout()}></Button>
      </Sidebar>
      <i className='pi pi-align-justify menu-header' onClick={() => setVisible(true)}></i>
      <span className='app-page-title'>{activeLocation}</span>
    </div>
  );
}

export default Menu;
