import React, { useState, useCallback, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import { userService } from './services';
import { ThemeContext } from './contexts/theme-context';
import 'primeicons/primeicons.css';
import './styles/app.scss';
import './styles/app-prime.scss';
import './styles/login.scss';
import './styles/variables.scss';

import Login from './apps/loginPage/login';
import Dashboard from './apps/dashboard/dashboard';
import ForgottenPassword from './apps/forgotPassword/forgotPassword';
import SetPassword from './apps/setPassword/setPassword';
import Processes from './apps/processes/process';
import ConfigTable from './apps/configurations/configurations';
import Header from './components/header';
import Menu from './components/menu';

function App() {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'light',
  );

  const fetchUser = useCallback(async () => {
    try {
      await userService.checkLoginStatus();
    } catch (e) {}// eslint-disable-line
  }, []);

  useEffect(() => {
    fetchUser();
    setPath(location.pathname);
  }, [location, fetchUser]);

  const routes
  = <Routes>
    <Route path='/' element={<Login />} />
    <Route path='/dashboard' element={<Dashboard />} />
    <Route path='/forgotten-password' element={<ForgottenPassword />} />
    <Route path='/set-password' element={<SetPassword />} />
    <Route path='/processes' element={<Processes />} />
    <Route path='/configurations' element={<ConfigTable />} />
    <Route path='*' element={<Navigate to='/' />} />
  </Routes>;

  return (
    <>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <div className={`theme-wrapper ${theme}`}>
          <div className='app-body'>
            <PrimeReactProvider>
              {path === '/' || path.includes('-password')
                ? null
                : <div>
                  <Header/>
                  <Menu />
                </div>
              }
              {routes}
            </PrimeReactProvider>
          </div>
        </div>
      </ThemeContext.Provider>
    </>
  );
}

export default App;
